* {
    box-sizing: border-box; 
}

@font-face {
    font-family: NewYork;
    src: url(Future-History.woff);
  }
html {margin: 0; padding: 0; font-size: 12px;}

body {
    margin: 0;
    padding: 0;
    overflow: hidden!important;  
    width:100%;
    height:100%;
    background-color: #141313;
    color: #fff;
  }

  body:after {
    animation: grain 8s steps(10) infinite;
    background-image: url("noise2@2x.png");
    content: "";
    height: 300%;
    left: -50%;
    opacity: 0.06;
    position: fixed;
    top: -100%;
    width: 300%;
  }
  

  
  @keyframes grain {
    0%, 100% { transform:translate(0, 0) }
    10% { transform:translate(-5%, -10%) }
    20% { transform:translate(-15%, 5%) }
    30% { transform:translate(7%, -25%) }
    40% { transform:translate(-5%, 25%) }
    50% { transform:translate(-15%, 10%) }
    60% { transform:translate(15%, 0%) }
    70% { transform:translate(0%, 15%) }
    80% { transform:translate(3%, 35%) }
    90% { transform:translate(-10%, 10%) }
  }


iframe {
    position: fixed;
    width:100%;
    height:100%;
    top:0;
    left:0;
    z-index: 99;
    opacity: .9;
}

/* .head {
    position: absolute;
    width:60%;
    max-width:360px;
    left:50%;
    top:50%;
    transform: translate(-50%,-50%);
}

.head img {
    width:100%;
} */

.coffee {
    position: absolute;
    top:40px;
    left:30px;
    font-size: .6rem;
    font-family: NewYork;
    letter-spacing: 1px;
   text-transform: uppercase;
   display: flex;
   align-items: center;
}

.intro {
    position: absolute;
    top: 40px;
    right: 30px;
    height:20px;
    font-size: .6rem;
    font-family: NewYork;
    letter-spacing: 1px;
   text-transform: uppercase;
   display: flex;
   align-items: center;
}


.coffee img {
    width:20px;
    margin-right: 8px;
}


.clock {
    position: absolute;
    bottom: 40px;
    left: 30px;
    font-size: .6rem;
    font-family: NewYork;
    letter-spacing: 1px;
   text-transform: uppercase;
}

.clock::before {
    content: '\274B  Copenhagen ';
}

.comingSoon a{
    position: absolute;
    bottom: 40px;
    right: 30px;
    font-size: .6rem;
    font-family: NewYork;
    letter-spacing: 1px;
   text-transform: uppercase;
   text-decoration: none;
   cursor:pointer;
   color:#fff;
   z-index: 99;
}

.comingSoon a::before {
    content: '\274B  ';
}

.comingSoon a::after, .clock::after{
    content: '  \274B';
}



@media screen and (max-width:980px) {
    body {
        display:block;
        width:100%;
    }
    canvas {
        padding:0;
    }

    .intro, .comingSoon a {
        right:20px;
    }

    .coffee, .clock {
        left:20px;
    }

    .coffee img {
        width: 16px;
    }

    h3.LargeText {
        font-size:16vw;
    }
    .list-inline li{
        width:66.6vw;
    }
    
}

  